import "../../Assets/Css/Home.css";
import Description from "./Description";
import Services from "./Services";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import traingvid from "../../Assets/Video/trading.mp4";

const Home = () => {
  return (
    <>
      <div className="main">
        <video src={traingvid} autoPlay loop muted />
        <div className="contents">
          <h1 className="heading">
            The Top Stock Market Training<br></br>
            Institute in India
          </h1>
          <h3 className="paragraph">
            Kickstart your journey to become a successful trader & investor
            <br></br>
            with one of the best stock market training programs available today.
          </h3>
          <Link to="/contact">
            <button className="leanr_mores">LEARN MORE</button>
          </Link>
        </div>
      </div>

      <Description />
      <Services />
      <Footer />
    </>
  );
};
export default Home;

{
  /* <div className="Main_bg">
<div className="overlay">
<video src={traingvid} autoPlay loop muted/>
<h1 className="heading">The Top Stock Market Training<br></br>
Institute in India</h1>
<h3 className="paragraph">Kickstart your journey to become a successful trader & investor<br></br>
with one of the best stock market training programs available today.</h3>
<button className="leanr_more">LEARN MORE</button>
</div>
</div> */
}
