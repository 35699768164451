import { Link } from "react-router-dom";
import "../../Assets/Css/Foot.css";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { MdKeyboardArrowRight } from "react-icons/md";
const Footer = () => {
  return (
    <>
      <div className="foo_flex">
        <div className="col_1">
          <h2 style={{ color: "#FF8400", fontSize: "19px", fontWeight: "600",textAlign:"justify" }} className="h2_2">
            About us
          </h2>
          <p style={{ marginTop: "30px", textAlign: "justify" }}>
            Investment Empire is a renowned Share Market Training Academy in
            Chh.Sambhajinagar(Aurangabad) that offers wide rage of Stock Market and Investment training
            programs with real-time trading exposure.
          </p>
        </div>
        <div className="col_1">
          <h2 style={{  color: "#FF8400", fontSize: "19px", fontWeight: "600",textAlign:"justify" }} className="h2_2">
            Quiks Links
          </h2>
          <ul className="li_i" style={{textAlign:"justify"}}>
            <Link to=""><MdKeyboardArrowRight/>Home</Link><br></br>
            <Link to=""><MdKeyboardArrowRight/>About Us</Link><br></br>
            <Link to=""><MdKeyboardArrowRight/>Blog</Link><br></br>
            <Link to=""><MdKeyboardArrowRight/>Contact Us</Link>
          </ul>
        </div>
        <div className="col_1">
          <h2 style={{ color: "#FF8400", fontSize: "19px", fontWeight: "600",textAlign:"justify" }} className="h2_2">
            Popular Courses
          </h2><br></br>
          <div style={{textAlign:"justify",width:"98%"}}>
          <MdKeyboardArrowRight/><Link to="">Advanced Futures & Options Course in Mumbai</Link><br></br>
          <Link to=""><MdKeyboardArrowRight/>Advanced Technical Analysis Course
          Basic Equity Fundamental Analysis Course</Link><br></br>
          <Link to=""><MdKeyboardArrowRight/>Basic Technical Analysis Course</Link><br></br>
          <Link to=""><MdKeyboardArrowRight/>Commodity and Currency Course</Link><br></br>
          <Link to="">Intraday Options Trading Course (IOTC)</Link><br></br>
</div>
        </div>
        <div className="col_1">
          <h2 style={{ color: "#FF8400", fontSize: "19px", fontWeight: "600" ,textAlign:"justify"}} className="h2_2">
            Get in Touch
            </h2>
            <br></br>
            <div style={{textAlign:"justify"}}>
            <Link to=""><FaPhoneAlt style={{color: "#FF8400"}}/>{"   "}+918010907727</Link><br></br>
            <Link to=""><FaPhoneAlt style={{color: "#FF8400"}}/>{" "}{" "}+918727266255</Link><br></br>
            <Link to=""><MdEmail style={{color: "#FF8400"}}/>{" "}info@investmentempire.in</Link><br></br>
            <Link to=""><FaLocationDot style={{color: "#FF8409"}}/>{" "}B-13, 2nd Floor, Prozone Trade Center, beside Prozone Mall, MIDC Industrial Area, Chilkalthana, Chhatrapati Sambhajinagar (Aurangabad), Maharashtra 431003</Link>
          </div>
         
        </div>
      </div>

      <div className="footrt">
        <div className="trademars"><h6>Copyright © 2024 Investment Empire Powered By Mk e-tech Private Limited</h6>
        
        </div>
     </div>
    </>
  );
};
export default Footer;
