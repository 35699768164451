import "../../Assets/Css/Services.css";
import { Data } from "./Data";


const Services = () => {
  return (
    <>
      <div className="wrapper">
        <div className="cardname">
        <h1 className="why">Why Choose Us</h1>
        </div>
        <div className="card_flex">
        {Data.map((Enu) => (
          <div className="card">
           
                <img src={Enu.img} className="logom" height={40} width={40}/>
                 <h5>{Enu.name}</h5> 
                  <p>{Enu.Desc}</p>
          </div>
        ))}
        </div>
      </div>
    </>
  );
};
export default Services;
