import img1 from "../../Assets/Images/certificate.png";
import img2 from "../../Assets/Images/growth-1.png";
import img3 from "../../Assets/Images/user.png";
import img4 from "../../Assets/Images/book.png";
import img5 from "../../Assets/Images/stock.png";
import img6 from "../../Assets/Images/care.png";

export const Data=[
  {
    img:img1,
    "name": "Expert Instructors",
    "Desc":"At Tips2Trade, students are taught by industry experts with years of experience in stock trading. These instructors bring a wealth of practical knowledge and insights to the classroom, providing students with real-world examples and case studies."
  },
  {
    img:img2,
    "name": "Practical Approach",
    "Desc":"We believe in a practical approach to learning. The institute emphasizes hands-on training and real-time market analysis, allowing students to apply their knowledge in real trading scenarios."
  },
  {
    img:img3,
    "name": "Personalized Learning",
    "Desc":"Recognizing that each student has unique learning needs and goals, Tips2Trade offers personalized learning solutions. The institute provides one-on-one mentoring and coaching sessions where students can receive individual attention and guidance."
  },
  {
    img:img4,
    "name": "Comprehensive Curriculum",
    "Desc":"One of the key factors that set Tips2Trade apart from other share market institutes is its comprehensive curriculum. The institute offers a wide range of courses designed to cater to the needs of both beginners and experienced traders."
  },
  {
    img:img5,
    "name": "Live Market Exposure",
    "Desc":"Immerse yourself in the dynamics of the stock market through live market exposure. Witness real-time market scenarios in dedicated sessions that allow you to apply your knowledge, understand market trends, and make informed decisions with confidence."
  },
  {
    img:img6,
    "name": "Strong Community",
    "Desc":"Tips2Trades boasts a strong and supportive community of traders and investors. The institute organizes regular workshops, seminars, and networking events where students can connect with like-minded individuals and industry professionals."
  }
]
