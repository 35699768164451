import about_bg from "../../Assets/Images/about_bg.jpg";
import Footer from "../Main_Uis/Footer";
import "../../Assets/Css/About.css";
import { FaHandPointRight } from "react-icons/fa";
import tradingV from "../../Assets/Video/trading.mp4"

const About = () => {
  return (
    <div>
     <video src={tradingV} autoPlay loop muted className="intra_vid" />
      <h3 className="about_tii">
        Welcome to InvestmentEmpire - A Leading Stock Market Training Institute
        in India
      </h3>
      <p className="about_p">
        We are one of the leading financial trainers in India, offering stock
        market trading courses via online as well as classroom sessions. With
        over 20+ years of teaching, we provide one on-one personalised and group
        online stock market courses for individuals learning to seek knowledge
        and practical training in the stock market field.
        <br></br><br></br>
        Investment Empire is helmed by financial professionals who aim at
        providing comprehensive stock trading courses for students, corporate
        executives, analysts, novice & experienced investors and traders,
        working professionals and even homemakers who are keen on learning
        professional and authentic trading techniques for building a solid
        career in the stock market or even utilising their learned knowledge for
        managing their finances.<br></br><br></br>
        At Investment Empire, we believe that every individual must have a fundamental
        knowledge of stock markets and should be able to manage their finances
        smartly for having a secure and bright future.
      </p>
      <div className="whychoose">
        <h1 style={{color:"black",padding:"20px 0px",fontWeight:"600",fontSize:"2rem"}}>WHY CHOOSE US</h1>
        <div className="" style={{textAlign:"justify",width:"70%",justifyContent:"center",marginLeft:"17%"}}>
       <h6><FaHandPointRight style={{color:"orange"}}/> More than two decades of industry experience have made us one of the best institutions to learn stock market courses in Mumbai from.</h6><br></br>
       <h6><FaHandPointRight  style={{color:"orange"}}/> We have a team of 22 stock market tutors, 300+ students and over 100000 graduates!</h6><br></br>
       <h6><FaHandPointRight  style={{color:"orange"}}/> Our stock market tutors help you gain confidence and the critical skills needed for making it big in the stock market world.</h6><br></br>
       <h6><FaHandPointRight  style={{color:"orange"}}/> Our online share market courses focus on bridging the gap between theory and practical knowledge by teaching you crucial methods and techniques for innovative & successful trading and investing and finance management.</h6><br></br>
   </div>
      </div>

      <Footer/>
    </div>
  );
};
export default About;
