import "./Signup.css";
import Footer from "../Main_Uis/Footer";
const Signup = () => {
  return (
    <>

<div class="login-wrap">
    <div class="login-html">
      <input id="tab-1" type="radio" name="tab" class="sign-in" checked/><label for="tab-1" class="tab">Sign In</label>
      <input id="tab-2" type="radio" name="tab" class="sign-up"/><label for="tab-2" class="tab">Sign Up</label>
      <div class="login-form">
        <div class="sign-in-htm">
          <div class="group">
            <label for="user" class="label">USERNAME / EMAIL</label>
            <input id="user" type="text" class="input"/>
          </div>
          <div class="group">
            <label for="pass" class="label">Password</label>
            <input id="pass" type="password" class="input" data-type="password"/>
          </div>
          <div class="group">
            <input id="check" type="checkbox" class="check" checked/>
            <label for="check"><span class="icon"></span> Keep me Signed in</label>
          </div>
          <div class="group">
            <input type="submit" class="button" value="Sign In"/>
          </div>
          <div class="hr"></div>
         </div>

         {/* signup form */}
        <div class="sign-up-htm">
          <div class="group">
            <label for="user" class="label">Full Name *</label>
            <input id="user" type="text" class="input"/>
          </div>
          <div class="group">
            <label for="pass" class="label">Phone Number *</label>
            <input id="pass" type="password" class="input" data-type="password"/>
          </div>
          <div class="group">
            <label for="pass" class="label">Email ID *</label>
            <input id="pass" type="password" class="input" data-type="password"/>
          </div>
          <div class="group">
            <label for="pass" class="label">Create Password *</label>
            <input id="pass" type="text" class="input"/>
          </div>
          <div class="group">
            <label for="pass" class="label">Re-enter Password *</label>
            <input id="pass" type="text" class="input"/>
          </div>
          <div class="group">
            <input type="submit" class="button" value="Sign Up"/>
          </div>
        
        </div>
      </div>
    </div>
  </div>
  <Footer/>
    </>
  );
};
export default Signup;
