import stock from "../../Assets/Images/img_1.jpg";
import "../../Assets/Css/Desc.css";
import { FaPhoneAlt } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";

const Description = () => {

  const phoneNumber = '8010907727';
  const message = 'Hello, I need help with...';
  const encodedMessage = encodeURIComponent(message);
  const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  return (
    <>
      <div className="div">
        <div className="para">
            <br></br>
          <h1 className="til">Best Stock Market Classes in Chh.Sambhajinagar (Aurangabad) | Investment Empire</h1>
          <br></br>
          <h3 className="paras">
            Looking for the best stock market courses in Chh.Sambhajinagar (Aurangabad)? Investment Empire
            offers a wide range of online share market classes to choose from.
            <br></br><br></br>
            If you want to learn about the stock market and become a financial  
            expert, go no further than our Share Market Classes in Chh.Sambhajinagar(Aurangabad), which
            provide a thorough learning experience for both novices and seasoned
            investors. 
            <br></br><br></br>
            From stock analysis to portfolio management, our
            instructors guide you through every aspect of the stock market,
            providing practical insights that go beyond theoretical knowledge
            and bringing real-world scenarios into the classroom, allowing you
            to grasp the complexities of the market with confidence. 
            <br></br><br></br>
            The leading
            share market classes in Chh.Sambhajinagar(Aurangabad), Investment Empire, enter the picture at
            this point. With the goal of providing people with the information
            and abilities needed to succeed in stock trading, Investment Empire has
            made a name for itself as the nation’s top provider of share market
            education.
            <br></br><br></br>
             Investment Empire distinguishes itself from other share market
            trading schools primarily through its extensive curriculum. The
            school provides a large selection of courses.
          </h3>
        </div>
        <div >
            <img src={stock} height={325} className="img"/>
        </div>
      </div>

      <div className="iconss">
        <div className="faw">
         <Link to={whatsappURL} > <BsWhatsapp/></Link>
        </div>
        <div>
        <Link to={phoneNumber}><FaPhoneAlt className="ph"/></Link>
        </div>
      </div>
    </>
  );
};
export default Description;
