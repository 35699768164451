// src/components/Navbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../Assets/Css/Navbar.css";
import logoes from "../../Assets/Images/Brand_logo.png";
import { IoIosArrowDown } from "react-icons/io";
import { Navigate } from "react-router-dom";

const Navbar = () => {

  return (
    <>
      <section class="top-nav">
        <div><img src={logoes} height={50}/></div>
        <input id="menu-toggle" type="checkbox" />
        <label class="menu-button-container" for="menu-toggle">
          <div class="menu-button"></div>
        </label>
        <ul className="menu"> 
          <li><Link to="/">HOME</Link></li>
          <li ><Link to="About">ABOUT US</Link></li>
               <li>
                 <a href="">TECHNICAL<IoIosArrowDown/></a>
                 <ul className="dropdown">
                   <li><a href="">Basic Technical Analysis</a></li>
                   <li><a href="">Advanced Technical Courses</a></li>
                   <li><a href="">Master Trader Technical Analysis Courses</a></li>
                 </ul>
               </li>
          <li>
            <a href="">F&O<IoIosArrowDown/></a>
            <ul className="dropdown">
                   <li><a href="">Basic Future & Options Course</a></li>
                   <li><a href="">Advanced Future & Options Courses In Aurangabad</a></li>
                   <li><a href="">Intraday Options Trading Courses(IOTC)</a></li>
                 </ul>
          </li>
          <li>
           <a href="" >OTHER COURSES<IoIosArrowDown/></a>
            <ul className="dropdown">
                   <li><a href="">Basic Quality Fundamental Analysis Course</a></li>
                   <li><a href="">Commodity And Currency Couse</a></li>
                   <li><a href="">Order Flow Analysis Course</a></li>
                   <li><a href="">Long Term Fund Management</a></li>
                   <li><a href="">Money/Capital Management Program</a></li>
                   <li><a href="">Online Live Training Program</a></li>
                 </ul>
            </li>
          <li>
            <a href="">FAQ <IoIosArrowDown/></a>
            <ul className="dropdown">
                   <li><a href="">Basic Quality Fundamental Analysis Course</a></li>
                   <li><a href="">Commodity And Currency Couse</a></li>
                   <li><a href="">Order Flow Analysis Course</a></li>
                   <li><a href="">Long Term Fund Management</a></li>
                   <li><a href="">Money/Capital Management Program</a></li>
                   <li><a href="">Online Live Training Program</a></li>
                 </ul>
          </li>
          <li><Link to="/contact">CONTACT</Link></li>
          <li><Link to="/signup">LOGIN/SIGNUP</Link></li>
        </ul>
      </section>
    </>
  );
};

export default Navbar;
